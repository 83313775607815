import "./src/scss/mothersoft.scss"
import "bootstrap/dist/js/bootstrap.min"
import "bootstrap-icons/font/bootstrap-icons.min.css"
import React from "react"
import { ThemeContext } from "./src/context/CurrencyContext"
import { GlobalContextProvider } from "./src/context/GlobalContext"
import { QueryClient, QueryClientProvider } from "react-query"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
const queryClient = new QueryClient()
export const wrapPageElement = ({ element, props }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf0-zsoAAAAAEgfB74UZw2aBSOUatBbmxXC2f8e">
      <QueryClientProvider client={queryClient}>
        <GlobalContextProvider {...props}>
          <ThemeContext>{element}</ThemeContext>
        </GlobalContextProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  )
}
/* export const onClientEntry = () => {

}
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
      `This Website has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
      window.location.reload()
  }
} */
